import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import LandingPage from './components/LandingPage';
import Auth from './components/Auth';
import UserPage from './components/Authed/UserPage';
import AdminPage from './components/Authed/AdminPage';

const theme = createTheme({
  // You can customize your theme here
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <LandingPage />
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserPage.css';
import Session from "supertokens-auth-react/recipe/session";
import { getApiDomain } from "../../config/supertokenConfig";
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import RequestsGrid from './RequestsGrid';

interface Request {
  id: string;
  subject: string;
  object: string;
  moderated: boolean;
  status: string;
}

export default function UserPage () {
  const [subject, setSubject] = useState('');
  const [object, setObject] = useState('');
  const [requests, setRequests] = useState<Request[]>([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  useEffect( () => {
    axios.get<Request[]>(getApiDomain() + "/questions")
      .then(response => {
        setRequests(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${getApiDomain()}/questions`,
        { subject, object },
        {
          headers: {
            Authorization: 'Bearer ' + await Session.getAccessToken()
          }
        }
      );

      setSnackbar({ open: true, message: 'Request submitted successfully!', severity: 'success' });

      const responseList = await axios.get<Request[]>(
        `${getApiDomain()}/questions`,
        {
          headers: {
            Authorization: 'Bearer ' + await Session.getAccessToken()
          }
        }
      );
      // Add the new request to the list
      setRequests(responseList.data);

      // Reset form fields
      setSubject('');
      setObject('');
    } catch (error) {
      console.error('Error submitting request:', error);
      setSnackbar({ open: true, message: 'Error submitting request. Please try again.', severity: 'error' });
    }
  };



  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="md" className="user-page">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          User Dashboard
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Submit new request
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Subject"
              variant="outlined"
              margin="normal"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Object"
              variant="outlined"
              margin="normal"
              value={object}
              onChange={(e) => setObject(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </form>
        </Paper>

        <RequestsGrid requests={requests} />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

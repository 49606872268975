import React, { useEffect, useState } from 'react';
import './AdminPage.css';
import { getApiDomain } from "../../config/supertokenConfig";

import {
  Container,
  Typography,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import AdminGrid from './AdminGrid';
import axios from 'axios';

interface AdminRequest {
  id: string;
  userId: string;
  email: string;
  subject: string;
  object: string;
}

export default function AdminPage () {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });


  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ px: { xs: 2, sm: 3, md: 4 } }} className="user-page">
      <Box my={4} sx={{ maxWidth: '2000px', margin: '0 auto' }}>
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ width: '100%', mb: 4 }}>
          Admin Dashboard
        </Typography>
        <AdminGrid />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

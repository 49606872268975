import React, { useEffect, useState } from 'react';
import './LandingPage.css';
import Auth from './Auth';

import Session from "supertokens-auth-react/recipe/session";

const LandingPage: React.FC = () => {
  const [showAuth, setShowAuth] = useState(false);

  const handleSignUp = () => {
    // if (Session.doesSessionExist()) {

    // }
    setShowAuth(true);
  };

  if (showAuth) {
    return <Auth />;
  }

//   useEffect(() => {
//     if (Session.doesSessionExist()) {

//     }
//   })

  return (
    <div className="landing-page">
      <header>
        <nav>
          <div className="logo">Social Investigation</div>
          <ul>
            <li><a href="#features">Features</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
        <div className="hero">
          <h1>Connect, Share, and Engage</h1>
          <p>Join our vibrant community and make a difference</p>
          <button className="cta-button" onClick={handleSignUp}>Sign Up</button>
        </div>
      </header>
      <main>
        <section id="features" className="features">
          <h2>Key Features</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <i className="fas fa-users"></i>
              <h3>Connect</h3>
              <p>Build meaningful relationships with like-minded individuals</p>
            </div>
            <div className="feature-item">
              <i className="fas fa-share-alt"></i>
              <h3>Share</h3>
              <p>Share your thoughts, experiences, and ideas with the community</p>
            </div>
            <div className="feature-item">
              <i className="fas fa-lightbulb"></i>
              <h3>Discover</h3>
              <p>Explore interesting content and new perspectives</p>
            </div>
            <div className="feature-item">
              <i className="fas fa-comments"></i>
              <h3>Engage</h3>
              <p>Participate in lively discussions and debates</p>
            </div>
          </div>
        </section>
        <section id="about" className="about">
          <h2>About Our Project</h2>
          <p>Social Investigation is a platform designed to bring people together, foster meaningful connections, and create positive change in our communities. We believe in the power of collective action and shared knowledge.</p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 Social Investigation. All rights reserved.</p>
        <div className="social-links">
          <a href="#" aria-label="Facebook"><i className="fab fa-facebook"></i></a>
          <a href="#" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
          <a href="#" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;

import React from 'react';
import './Auth.css';
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route, Navigate } from "react-router-dom";
import { PreBuiltUIList, SuperTokensConfig, ComponentWrapper } from "../config/supertokenConfig";
import Home from './Authed/Home';

SuperTokens.init(SuperTokensConfig);

const Auth: React.FC = () => {
    // This is a placeholder function. In a real application, you would
    // implement proper role checking logic here.

    return (
        <SuperTokensWrapper>
            <ComponentWrapper>
                <div className="App app-container">
                    <Router>
                        <div className="fill">
                            <Routes>
                                {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), PreBuiltUIList)}

                                <Route
                                    path="/"
                                    element={
                                        <SessionAuth>
                                            <Home />
                                        </SessionAuth>
                                    }
                                />
                            </Routes>
                        </div>
                    </Router>
                </div>
            </ComponentWrapper>
        </SuperTokensWrapper>
    );
};

export default Auth;

import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { signOut } from "supertokens-auth-react/recipe/session";
import { recipeDetails } from "../../config/supertokenConfig";
import AdminPage from "./AdminPage";
import UserPage from "./UserPage";
import { getApiDomain } from "../../config/supertokenConfig";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { Box, Container, Typography, AppBar, Toolbar, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';
import LogoutIcon from '@mui/icons-material/Logout';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export default function AuthedPage() {
    const [authed, setAuthed] = useState(false);
    const [email, setEmail] = useState("");
    useEffect( () => {
        axios.get(getApiDomain() + "/sessioninfo")
          .then(response => {
            setEmail(response.data.email);
            setAuthed(response.data.authed);
          })
          .catch( error => {
            console.error(error);
          })
    }, []);

    const handleSignOut = async () => {
        await signOut();
        window.location.href = "/"; // Redirect to home page after sign out
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Welcome, {email}!
                    </Typography>
                    <Button
                        color="inherit"
                        onClick={handleSignOut}
                        endIcon={<LogoutIcon />}
                    >
                    Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <StyledPaper elevation={3}>
                    <Typography variant="h4" gutterBottom>
                    </Typography>
                    {authed ? <AdminPage /> : <UserPage /> }
                </StyledPaper>

            </Container>
        </Box>
    );
}

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { getApiDomain } from "../../config/supertokenConfig";
import Session from "supertokens-auth-react/recipe/session";

interface AdminRequest {
    id: string;
    email: string;
    subject: string;
    object: string;
  }

// interface AdminGridProps {
//   requests: AdminRequest[];
// }

const AdminGrid: React.FC = () => {

  const [data, setData] = useState<AdminRequest[]>([]);
  useEffect(() => {
    updateGrid();
  }, []);
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [editingCell, setEditingCell] = useState<{ id: string; field: 'subject' | 'object' } | null>(null);
  const [editValue, setEditValue] = useState('');

  function updateGrid() {
    axios.get<AdminRequest[]>(getApiDomain() + "/administration/notModerated")
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleApprove = async (request: AdminRequest) => {
    setLoading(prev => ({ ...prev, [request.id]: true }));
    try {
        const response = await axios.put(
            getApiDomain() + `/administration/update/${request.id}`,
        {
            subject: request.subject,
            object: request.object
        },
        {
            headers: {
                Authorization: 'Bearer ' + await Session.getAccessToken()
            }
        });
        console.log(response.data);
    } catch (error) {
      console.error('Error approving request:', error);
    } finally {
      updateGrid();
      setLoading(prev => ({ ...prev, [request.id]: false }));
    }
  };

  const handleDeny = async (requestId: string) => {
    setLoading(prev => ({ ...prev, [requestId]: true }));
    try {
        const response = await axios.put(
            getApiDomain() + `/administration/reject/${requestId}`,
        {
            headers: {
                Authorization: 'Bearer ' + await Session.getAccessToken()
            }
        });
        console.log(response.data);
    } catch (error) {
      console.error('Error rejecting request:', error);
    } finally {
      updateGrid();
      setLoading(prev => ({ ...prev, [requestId]: false }));
    }
  };

  const handleEdit = (id: string, field: 'subject' | 'object', value: string) => {
    setEditingCell({ id, field });
    setEditValue(value);
  };

  const handleSave = async (id: string, field: 'subject' | 'object') => {
    setLoading(prev => ({ ...prev, [id]: true }));
    let requestsUpdated = data.map((element) => {
        if (element.id === id) {
            return {...element, [field]: editValue }
        }
        return element;
    })
    setEditingCell(null);
    setData(requestsUpdated);
    setLoading(prev => ({ ...prev, [id]: false }));
  };

  const handleCancel = () => {
    setEditingCell(null);
    setEditValue('');
  };

  const renderEditableCell = (request: AdminRequest, field: 'subject' | 'object') => {
    const isEditing = editingCell?.id === request.id && editingCell?.field === field;

    if (isEditing) {
      return (
        <>
          <TextField
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            size="small"
            fullWidth
          />
          <Tooltip title="Save">
            <IconButton onClick={() => handleSave(request.id, field)} disabled={loading[request.id]}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton onClick={handleCancel} disabled={loading[request.id]}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        {request[field]}
        <Tooltip title="Edit">
          <IconButton onClick={() => handleEdit(request.id, field, request[field])} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>

      </>
    );
  };

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Users Requests
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Request ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Object</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((request, index) => (
              <TableRow key={request.id}>
                <TableCell>{index+1}</TableCell>
                <TableCell>{request.id}</TableCell>
                <TableCell>{request.email}</TableCell>
                <TableCell>{renderEditableCell(request, 'subject')}</TableCell>
                <TableCell>{renderEditableCell(request, 'object')}</TableCell>
                <TableCell>
                  {
                    <>
                    <Tooltip title="Approve">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleApprove(request)}
                        disabled={loading[request.id]}
                        sx={{ mr: 1 }}
                        >
                          <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deny">
                      <IconButton
                        color="error"
                        size="small"
                        onClick={() => handleDeny(request.id)}
                        disabled={loading[request.id]}
                      >
                        <DoDisturbOnIcon />
                      </IconButton>
                    </Tooltip>
                    </>

                    // <>
                    //   <Button
                    //     variant="contained"
                    //     color="primary"
                    //     size="small"
                    //     onClick={() => handleApprove(request)}
                    //     disabled={loading[request.id]}

                    //     sx={{ mr: 1 }}
                    //   >
                    //     {loading[request.id] ? 'Approving...' : 'Approve'}
                    //   </Button>
                    //   <Button
                    //     variant="contained"
                    //     color="error"
                    //     size="small"
                    //     onClick={() => handleDeny(request.id)}
                    //     disabled={loading[request.id]}
                    //   >
                    //     {loading[request.id] ? 'Denying...' : 'Deny'}
                    //   </Button>
                    // </>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AdminGrid;

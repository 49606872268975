import AuthedPage from "./AuthedPage";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

export default function Home() {
    const sessionContext = useSessionContext();

    if (sessionContext.loading === true) {
        return null;
    }


    return (
        <div className="fill" id="home-container">
            <AuthedPage  />
        </div>
    );
}

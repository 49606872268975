import React from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

interface Request {
  id: string;
  subject: string;
  object: string;
  moderated: boolean;
  status: string;
}

interface RequestsGridProps {
  requests: Request[];
}
// key={"user_grid_header"}
const RequestsGrid: React.FC<RequestsGridProps> = ({ requests }) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Your Requests
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow >
              <TableCell>№</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Object</TableCell>
              <TableCell>Moderated</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request, index) => (
              <TableRow key={request.id}>
                <TableCell>{index+1}</TableCell>
                <TableCell>{request.subject}</TableCell>
                <TableCell>{request.object}</TableCell>
                <TableCell>{request.moderated ? 'Yes' : 'No'}</TableCell>
                <TableCell>{request.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RequestsGrid;
